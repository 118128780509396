import MoneyAmount from '@/model/shared/MoneyAmount';
import { Advertiser } from '@/entities/ThirdParty/Advertiser';
import AnalyticsStats from '@/model/shared/analytics/AnalyticsStats';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import AnalyticsProductBreakdown from '@/entities/ThirdParty/AnalyticsProductBreakdown';

export default class AnalyticsCampaignBreakdown extends AnalyticsProductBreakdown {
  constructor(
    id = null,
    name = null,
    start = null,
    end = null,
    rate = null,
    format = null,
    bookedVolume = 0,
    lineItemId = null,
    clientConversion = null,
    analytic = new AnalyticsStats(),
    kpi = null,
    advertiser = new Advertiser(),
    inversionNet = new MoneyAmount(),
    netPrice = new MoneyAmount(),
    price = new MoneyAmount(),
    tag = [],
    todayMetrics = [],
    adops = null,
    accountName = null,
    reportId = null,
    margen = null,
    office = '',
    ownerName = '',
    billingEntityName = '',
    recordTypeName = '',
    accountManager = null,
    productName = ''
  ) {
    super(
      id,
      name,
      start,
      end,
      rate,
      format,
      bookedVolume,
      lineItemId,
      clientConversion,
      analytic,
      kpi,
      advertiser,
      inversionNet,
      netPrice,
      price,
      tag,
      todayMetrics,
      adops,
      accountName,
      reportId,
      margen,
      office,
      ownerName,
      billingEntityName,
      recordTypeName,
      accountManager
    );
    this.productName = productName;
  }

  /**
   *
   * @param {{attributes:AnalyticsCampaignBreakdown}} entity
   * @param {object} included
   * @return {AnalyticsCampaignBreakdown}
   */
  static create(entity, included) {
    const advertiser = included?.[ADVERTISER_RESOURCE]?.find(
      advertiser => advertiser.id === entity.relationships?.advertiser.data.id
    );
    const analytic = AnalyticsStats.build(entity.attributes.analytic);
    const inversionNet = new MoneyAmount(entity.attributes.inversionNet.currency, entity.attributes.inversionNet.value);
    const netPrice = new MoneyAmount(entity.attributes.netPrice.currency, entity.attributes.netPrice.value);
    const price = new MoneyAmount(entity.attributes.price.currency, entity.attributes.price.value);

    return new AnalyticsCampaignBreakdown(
      entity.id,
      entity.attributes.name,
      entity.attributes.start,
      entity.attributes.end,
      entity.attributes.rate,
      entity.attributes.format,
      entity.attributes.bookedVolume,
      entity.attributes.lineItemId,
      entity.attributes.clientConversion,
      analytic,
      entity.attributes.kpi,
      advertiser,
      inversionNet,
      netPrice,
      price,
      entity.attributes.tag,
      entity.attributes.todayMetrics,
      entity.attributes.adopsName,
      entity.attributes.accountName,
      entity.attributes.reportId,
      entity.attributes.margen,
      entity.attributes.office,
      entity.attributes.ownerName,
      entity.attributes.billingEntityName,
      entity.attributes.recordTypeName,
      entity.attributes.accountManager,
      entity.attributes.productName
    );
  }
}
