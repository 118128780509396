import { Advertiser } from './Advertiser';
import { isArray } from '@/utils/validation/assertions';
import MoneyAmount from '@/model/shared/MoneyAmount';
import { Opportunity } from '../shared/Opportunity';
import { ADVERTISER_RESOURCE, OPPORTUNITIES_RESOURCE } from '@/services/keys';

class Product {
  constructor(
    id = null,
    name = '',
    advertiser = new Advertiser(),
    initialDate = new Date(),
    endDate = new Date(),
    comments = [],
    kpi = 0,
    tag = [],
    adopsName = null,
    rate = null,
    price = null,
    volume = null,
    format = null,
    inversionNet = null,
    clientConversion = '',
    reportId = null,
    netPrice = null,
    accountName = null,
    lineItemId = null,
    margen = null,
    opportunity = new Opportunity()
  ) {
    this.id = id;
    this.name = name;
    this.advertiser = advertiser;
    this.initialDate = initialDate;
    this.endDate = endDate;
    this.comments = comments;
    this.kpi = kpi;
    this.tag = tag;
    this.rate = rate;
    this.price = price;
    this.volume = volume;
    this.format = format;
    this.inversionNet = inversionNet;
    this.clientConversion = clientConversion;
    this.reportId = reportId;
    this.netPrice = netPrice;
    this.adopsName = adopsName;
    this.accountName = accountName;
    this.lineItemId = lineItemId;
    this.margen = margen;
    this.opportunity = opportunity;
  }

  payload() {
    const { advertiser, ...params } = this;
    return {
      advertiserId: advertiser.id,
      ...params,
    };
  }

  metricsPayload() {
    const cmp = this;
    let tag = cmp.tag;
    if (!isArray(cmp.tag)) {
      // if tag is just a string we should get a matching array
      tag = cmp.tag.length > 0 ? [cmp.tag] : [];
    }
    return {
      id: cmp.id,
      kpi: cmp.kpi,
      tag,
      inversionNet: formatCurrency(cmp.inversionNet),
      clientConversion: cmp.clientConversion || 0,
    };
  }

  /**
   *
   * @param {{attributes:Product}} entity
   * @param {object} included
   * @return {Product}
   */
  static create(entity, included) {
    const opportunity = included?.[OPPORTUNITIES_RESOURCE]?.find(
      included => included.id === entity.relationships.opportunity?.data.id
    );
    const advertiser = opportunity
      ? included?.[ADVERTISER_RESOURCE]?.find(advertiser => advertiser.id === opportunity.advertiserId)
      : new Advertiser();

    return new Product(
      entity.id,
      entity.attributes.name,
      advertiser,
      entity.attributes.start,
      entity.attributes.end,
      [],
      entity.attributes.primaryKpiAmount,
      null,
      entity.attributes.adopsName,
      entity.attributes.rate,
      MoneyAmount.build({ currency: entity.attributes.currency, value: entity.attributes.price }),
      entity.attributes.volume,
      entity.attributes.format,
      null,
      entity.attributes.clientConversion,
      entity.attributes.reportId,
      MoneyAmount.build({ currency: entity.attributes.currency, value: entity.attributes.netPrice }),
      entity.attributes.accountName,
      entity.id,
      entity.attributes.margen,
      opportunity
    );
  }
}

function formatCurrency(attribute) {
  if (attribute) {
    const currency = attribute.currency?.id || attribute.currency || null;
    return { currency, value: attribute.value || null };
  }
  return { currency: null, value: null };
}

export default Product;
