import AnalyticsStats from '@/model/shared/analytics/AnalyticsStats';
import { Platform } from '@/entities/ThirdParty/Platform';

export default class AnalyticsPlatform {
  constructor(
    id = null,
    platform = null,
    start = null,
    end = null,
    accountManager = null,
    accountName = null,
    adopsName = null,
    format = null,
    kpi = null,
    office = '',
    ownerName = '',
    rate = null,
    recordTypeName = '',
    analytic = new AnalyticsStats()
  ) {
    this.id = id;
    this.platform = platform;
    this.start = start;
    this.end = end;
    this.accountManager = accountManager;
    this.accountName = accountName;
    this.adopsName = adopsName;
    this.format = format;
    this.kpi = kpi;
    this.office = office;
    this.ownerName = ownerName;
    this.rate = rate;
    this.recordTypeName = recordTypeName;
    this.analytic = analytic;
  }

  /**
   *
   * @param {{attributes:AnalyticsPlatform}} entity
   * @param {object} included
   * @return {AnalyticsPlatform}
   */
  static create(entity) {
    const attr = entity.attributes;
    const analytic = AnalyticsStats.build(entity.attributes.analytic);

    return new AnalyticsPlatform(
      entity.id,
      new Platform(null, entity.attributes.platform),
      attr.start,
      attr.end,
      attr.accountManager,
      attr.accountName,
      attr.adopsName,
      attr.format,
      attr.kpi,
      attr.office,
      attr.ownerName,
      attr.rate,
      attr.recordTypeName,
      analytic
    );
  }
}
