import SelectOption from '@/model/shared/SelectOption';

export class SalesforceFilter {
  constructor(options) {
    this.options = options;
  }

  static create(entity) {
    return new SalesforceFilter(
      entity.attributes.data.map(item => {
        const value = Object.values(item)[0];
        return new SelectOption(value, value);
      })
    );
  }
}
