export default class MetricsGlobal {
  constructor(clicks = 0, invalidClicks = 0, impressions = 0, leads = 0, viewability = 0, views = 0, adStarted = 0) {
    this.clicks = clicks;
    this.invalidClicks = invalidClicks;
    this.impressions = impressions;
    this.leads = leads;
    this.viewability = viewability;
    this.views = views;
    this.adStarted = adStarted;
  }

  static create(entity) {
    if (entity) {
      return new MetricsGlobal(
        entity.clicks,
        entity.invalidClicks,
        entity.impressions,
        entity.leads,
        entity.viewability,
        entity.views,
        entity.adStarted
      );
    }
    return new MetricsGlobal();
  }
}
