import { CONTEXTS } from '../../model/shared/contexts';
import { ClientShared } from '../shared/ClientShared';
import { Address } from '../shared/Address';

export class ThirdPartyClient extends ClientShared {
  constructor(
    id = '',
    name = '',
    email = '',
    contactName = '',
    isExternal = false,
    users = [],
    taxId = '',
    address = new Address()
  ) {
    super(id, name, email, CONTEXTS.THIRD_PARTY.id, contactName, isExternal, users, taxId);
    this.address = address;
  }

  payload() {
    const { users, ...params } = this;

    return {
      ...params,
      userIds: users.map(user => user.id),
    };
  }

  /**
   *
   * @param {{id:string, attributes: ThirdPartyClient}} entity
   * @param {Object} included
   * @return {ThirdPartyClient}
   */
  static create(entity, included) {
    const linkedUsers = entity.relationships?.linkedUsers.data;
    const users = linkedUsers && included?.users?.filter(user => linkedUsers.find(linkUser => linkUser.id === user.id));

    return new ThirdPartyClient(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.contactName,
      entity.attributes.isExternal,
      users,
      entity.attributes.taxId,
      entity.attributes.address
    );
  }
}
