import { stringToDate } from '@/filters/dateFilters';
import { PlatformProvider } from '@/entities/ThirdParty/PlatformProvider';
import { ThirdPartyClient } from './ThirdPartyClient';
import ProviderConfig from '@/entities/ThirdParty/ProviderConfig';
import { PROVIDERS_RESOURCE } from '@/services/keys';
import {
  PLATFORM_STATUS,
  PLATFORM_STATUS_DEFAULT,
  IMPORTING_STATUS,
} from '@/model/modules/thirdParty/platform/PlatformConnectionStatus';

const MIN_HOURS_SINCE_LAST_IMPORT = 1;

export class Platform {
  constructor(
    id = null,
    name = '',
    type = 'DEMAND', // by default all Platform Connection are DEMAND
    platformProvider = new PlatformProvider(),
    client = new ThirdPartyClient(),
    providerConfig = new ProviderConfig(),
    status = null,
    messageErrorStatus = null,
    updatedAt = null,
    lastImport = null
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.platformProvider = platformProvider;
    this.client = client;
    this.providerConfig = providerConfig;
    this.updatedAt = updatedAt;
    this.status = status || PLATFORM_STATUS_DEFAULT;
    this.messageErrorStatus = messageErrorStatus;
    this.lastImport = lastImport;
  }

  /**
   * @param {{id:string, relationships: object, attributes: Platform}} entity
   * @param {Array.<object>} included
   * @return {Platform}
   */
  static create(entity, included) {
    const providerId = entity.relationships?.provider?.data.id;
    const platformProvider = included?.[PROVIDERS_RESOURCE].find(p => p.id === providerId);
    const updatedAt = entity.attributes.updatedAt;
    const status = PLATFORM_STATUS.find(status => status.id === entity.attributes.status);
    const lastImport = entity.attributes.lastImport;

    return new Platform(
      entity.id,
      entity.attributes.name,
      entity.attributes.type,
      platformProvider,
      entity.attributes.client,
      new ProviderConfig(null, entity.attributes.providerConfigId),
      status,
      entity.attributes.messageErrorStatus,
      updatedAt ? stringToDate(updatedAt) : null,
      lastImport ? stringToDate(lastImport) : null
    );
  }

  payload() {
    const { platformProvider, providerConfig, client, name, type } = this;

    return {
      providerId: platformProvider.id,
      providerConfigId: providerConfig.id,
      clientId: client.id,
      name,
      type,
    };
  }

  reload() {
    this.status = IMPORTING_STATUS;
  }

  canReloadByStatusRestriction() {
    return this.status.id !== IMPORTING_STATUS.id;
  }

  canReloadByTimeRestriction() {
    if (!this.lastImport) return true;
    const newDate = new Date();
    const dateNow = new Date(
      newDate.getUTCFullYear(),
      newDate.getUTCMonth(),
      newDate.getUTCDate(),
      newDate.getUTCHours(),
      newDate.getUTCMinutes(),
      newDate.getUTCSeconds()
    );
    return Math.abs(dateNow.getTime() - new Date(this.lastImport).getTime()) / 36e5 > MIN_HOURS_SINCE_LAST_IMPORT;
  }
}
