import MoneyAmount from '@/model/shared/MoneyAmount';
import AnalyticsStats from '@/model/shared/analytics/AnalyticsStats';
import { Advertiser } from '@/entities/ThirdParty/Advertiser';
import { Platform } from '@/entities/ThirdParty/Platform';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import AnalyticsProduct from '@/entities/ThirdParty/AnalyticsProduct';

export default class AnalyticsCampaign extends AnalyticsProduct {
  constructor(
    id = null,
    name = null,
    start = null,
    end = null,
    rate = null,
    format = null,
    bookedVolume = 0,
    lineItemId = null,
    clientConversion = null,
    iasMetrics = null,
    analytic = new AnalyticsStats(),
    kpi = null,
    advertiser = new Advertiser(),
    inversionNet = new MoneyAmount(),
    netPrice = new MoneyAmount(),
    price = new MoneyAmount(),
    tag = [],
    todayMetrics = [],
    adops = null,
    accountName = null,
    reportId = null,
    margen = null,
    office = '',
    ownerName = '',
    billingEntityName = '',
    recordTypeName = '',
    accountManager = null,
    campaignId = null,
    productId = null,
    productName = null,
    platform = null
  ) {
    super(
      id,
      name,
      start,
      end,
      rate,
      format,
      bookedVolume,
      lineItemId,
      clientConversion,
      iasMetrics,
      analytic,
      kpi,
      advertiser,
      inversionNet,
      netPrice,
      price,
      tag,
      todayMetrics,
      adops,
      accountName,
      reportId,
      margen,
      office,
      ownerName,
      billingEntityName,
      recordTypeName,
      accountManager
    );
    this.campaignId = campaignId;
    this.productId = productId;
    this.productName = productName;
    this.platform = platform;
  }

  /**
   *
   * @param {{attributes:AnalyticsCampaign}} entity
   * @param {object} included
   * @return {AnalyticsCampaign}
   */
  static create(entity, included) {
    const advertiser = included?.[ADVERTISER_RESOURCE]?.find(
      advertiser => advertiser.id === entity.relationships?.advertiser.data.id
    );
    const analytic = AnalyticsStats.build(entity.attributes.analytic);
    const inversionNet = new MoneyAmount(entity.attributes.inversionNet.currency, entity.attributes.inversionNet.value);
    const netPrice = new MoneyAmount(entity.attributes.netPrice.currency, entity.attributes.netPrice.value);
    const price = new MoneyAmount(entity.attributes.price.currency, entity.attributes.price.value);

    return new AnalyticsCampaign(
      entity.id,
      entity.attributes.campaignName,
      entity.attributes.start,
      entity.attributes.end,
      entity.attributes.rate,
      entity.attributes.format,
      entity.attributes.bookedVolume,
      entity.id,
      entity.attributes.clientConversion,
      entity.attributes.iasMetrics,
      analytic,
      entity.attributes.kpi,
      advertiser,
      inversionNet,
      netPrice,
      price,
      entity.attributes.tag,
      entity.attributes.todayMetrics,
      entity.attributes.adopsName,
      entity.attributes.accountName,
      entity.attributes.reportId,
      entity.attributes.margen,
      entity.attributes.office,
      entity.attributes.ownerName,
      entity.attributes.billingEntityName,
      entity.attributes.recordTypeName,
      entity.attributes.accountManager,
      entity.attributes.campaignId,
      entity.attributes.productId,
      entity.attributes.productName,
      new Platform(null, entity.attributes.platform)
    );
  }
}
