export class PlatformProvider {
  constructor(id = null, name = '') {
    this.id = id;
    this.name = name;
  }

  static create(entity) {
    return new PlatformProvider(entity.id, entity.attributes.name);
  }
}
