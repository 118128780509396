import MoneyAmount from '@/model/shared/MoneyAmount';
import Metrics from '@/model/shared/analytics/Metrics';

export default class AnalyticsStats {
  constructor(breakdown = '', cost = new MoneyAmount(), metrics = new Metrics()) {
    this.breakdown = breakdown;
    this.cost = cost;
    this.metrics = metrics;
  }

  static build(entity) {
    if (entity) {
      const cost = new MoneyAmount(entity.cost?.currency, entity.cost?.amount);
      const metrics = Metrics.build(entity.metrics);
      return new AnalyticsStats(entity.breakdown, cost, metrics);
    }
    return new AnalyticsStats();
  }
}
