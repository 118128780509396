export default class ProviderConfig {
  constructor(id = null, providerId = null, name = '', available = true) {
    this.id = id;
    this.providerId = providerId;
    this.name = name;
    this.available = available;
  }

  static create(entity) {
    return new ProviderConfig(
      entity.id,
      entity.attributes.providerId,
      entity.attributes.name,
      entity.attributes.available
    );
  }
}
