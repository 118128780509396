const mapStyle = {
  standard: 'GROSS',
  custom: 'CURATE',
};

const mapStatus = {
  running: 'RUNNING',
};

const mapGroupBy = {
  date: 'Accumulated per Day',
  product: 'Product And Day',
  creative: 'Product, Creative And Day',
  accumulated_creative: 'Product, Date and Accumulated Creative',
};

export class ScheduleReport {
  constructor(
    id = '',
    name = '',
    userName = '',
    status = '',
    type = '',
    style = '',
    columns = '',
    groupBy = '',
    timezone = '',
    addresses = '',
    sfId = ''
  ) {
    this.id = id;
    this.name = name;
    this.userName = userName;
    this.status = status;
    this.type = type;
    this.style = style;
    this.columns = columns;
    this.groupBy = groupBy;
    this.timezone = timezone;
    this.addresses = addresses;
    this.sfId = sfId;
  }

  payload() {
    return this;
  }

  /**
   *
   * @param entity
   * @returns {ScheduleReport}
   */
  static create(entity) {
    return new ScheduleReport(
      entity.id,
      entity.attributes.name,
      entity.attributes.userName,
      mapStatus[entity.attributes.status],
      entity.attributes.config.type,
      mapStyle[entity.attributes.config.style],
      entity.attributes.config.columns,
      mapGroupBy[entity.attributes.config.groupBy],
      entity.attributes.config.timezone,
      entity.attributes.config.addresses,
      entity.attributes.sfId
    );
  }
}
