export default class MetricsCalculated {
  constructor(
    CPMNetSales = 0,
    CPVProductImpressions = 0,
    budget = 0,
    conversions = 0,
    cpc = 0,
    cpl = 0,
    cpm = 0,
    cpv = 0,
    ctr = 0,
    daysLeft = 0,
    delivery = 0,
    deliveryNeedPerDay = 0,
    deliveryNeedsPerDayIMPS = 0,
    desiredDelivery = 0,
    discrepancy = 0,
    eCPL = 0,
    leftToDeliver = 0,
    margin = 0,
    net = 0,
    pace = null,
    pacing = 0,
    vtr = 0,
    cpvisita = 0
  ) {
    this.CPMNetSales = CPMNetSales;
    this.CPVProductImpressions = CPVProductImpressions;
    this.budget = budget;
    this.conversions = conversions;
    this.cpc = cpc;
    this.cpl = cpl;
    this.cpm = cpm;
    this.cpv = cpv;
    this.ctr = ctr;
    this.daysLeft = daysLeft;
    this.delivery = delivery;
    this.deliveryNeedPerDay = deliveryNeedPerDay;
    this.deliveryNeedsPerDayIMPS = deliveryNeedsPerDayIMPS;
    this.desiredDelivery = desiredDelivery;
    this.discrepancy = discrepancy;
    this.eCPL = eCPL;
    this.leftToDeliver = leftToDeliver;
    this.margin = margin;
    this.net = net;
    this.pace = pace;
    this.pacing = pacing;
    this.vtr = vtr;
    this.cpvisita = cpvisita;
  }

  static create(entity) {
    if (!entity) return new MetricsCalculated();

    return new MetricsCalculated(
      entity.CPMNetSales,
      entity.CPVProductImpressions,
      entity.budget,
      entity.conversions,
      entity.cpc,
      entity.cpl,
      entity.cpm,
      entity.cpv,
      entity.ctr,
      entity.daysLeft,
      entity.delivery,
      entity.deliveryNeedPerDay,
      entity.deliveryNeedsPerDayIMPS,
      entity.desiredDelivery,
      entity.discrepancy,
      entity.eCPL,
      entity.leftToDeliver,
      entity.margin,
      entity.net,
      entity.pace,
      entity.pacing,
      entity.vtr,
      entity.cpvisita
    );
  }
}
