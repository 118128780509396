import { parseISO } from 'date-fns';
import { PLATFORM_RESOURCE } from '@/services/keys';
import { Platform } from './Platform';

/**
 * @name Cost
 * @class
 * @public
 */
export default class Cost {
  /**
   * @param {string} id
   * @param {string} currency
   * @param {number} amount
   * @param {number} day
   * @param {number} month
   * @param {number} year
   * @param {Platform} platform
   * @param {Date} date
   * @constructor
   */
  constructor(
    id = null,
    currency = 'EUR',
    amount = 0,
    day = 5,
    month = 10,
    year = 2021,
    platform = new Platform(),
    date = new Date()
  ) {
    this.id = id;
    this.currency = currency;
    this.amount = amount;
    this.day = day;
    this.month = month;
    this.year = year;
    this.platform = platform;
    this.date = date;
  }

  /**
   * @param {{id:string, relationships: object, attributes: Cost}} entity
   * @param {Array.<object>} included
   * @return {Cost}
   */
  static create({ id, attributes }, included) {
    const platformId = attributes.platformId;
    const platform = included[PLATFORM_RESOURCE].find(p => p.id === platformId);

    return new Cost(
      id,
      attributes.currency,
      attributes.amount,
      attributes.day,
      attributes.month,
      attributes.year,
      platform,
      parseISO(attributes.date)
    );
  }
}
