import MoneyAmount from '@/model/shared/MoneyAmount';
import { Advertiser } from '@/entities/ThirdParty/Advertiser';
import AnalyticsStats from '@/model/shared/analytics/AnalyticsStats';
import { ADVERTISER_RESOURCE } from '@/services/keys';

export default class AnalyticsProductBreakdown {
  constructor(
    id = null,
    name = null,
    start = null,
    end = null,
    rate = null,
    format = null,
    bookedVolume = 0,
    lineItemId = null,
    clientConversion = null,
    analytic = new AnalyticsStats(),
    kpi = null,
    advertiser = new Advertiser(),
    inversionNet = new MoneyAmount(),
    netPrice = new MoneyAmount(),
    price = new MoneyAmount(),
    tag = [],
    todayMetrics = [],
    adops = null,
    accountName = null,
    reportId = null,
    margen = null,
    office = '',
    ownerName = '',
    billingEntityName = '',
    recordTypeName = '',
    accountManager = null
  ) {
    this.id = id;
    this.name = name;
    this.start = start;
    this.end = end;
    this.rate = rate;
    this.format = format;
    this.bookedVolume = bookedVolume;
    this.lineItemId = lineItemId;
    this.clientConversion = clientConversion;
    this.analytic = analytic;
    this.kpi = kpi;
    this.advertiser = advertiser;
    this.inversionNet = inversionNet;
    this.netPrice = netPrice;
    this.price = price;
    this.tag = tag;
    this.todayMetrics = todayMetrics;
    this.adops = adops;
    this.accountName = accountName;
    this.reportId = reportId;
    this.margen = margen;
    this.office = office;
    this.ownerName = ownerName;
    this.billingEntityName = billingEntityName;
    this.recordTypeName = recordTypeName;
    this.accountManager = accountManager;
  }

  /**
   *
   * @param {{attributes:AnalyticsProduct}} entity
   * @param {object} included
   * @return {AnalyticsProduct}
   */
  static create(entity, included) {
    const advertiser = included?.[ADVERTISER_RESOURCE]?.find(
      advertiser => advertiser.id === entity.relationships?.advertiser.data.id
    );
    const analytic = AnalyticsStats.build(entity.attributes.analytic);
    const inversionNet = new MoneyAmount(entity.attributes.inversionNet.currency, entity.attributes.inversionNet.value);
    const netPrice = new MoneyAmount(entity.attributes.netPrice.currency, entity.attributes.netPrice.value);
    const price = new MoneyAmount(entity.attributes.price.currency, entity.attributes.price.value);

    return new AnalyticsProductBreakdown(
      entity.id,
      entity.attributes.name,
      entity.attributes.start,
      entity.attributes.end,
      entity.attributes.rate,
      entity.attributes.format,
      entity.attributes.bookedVolume,
      entity.attributes.lineItemId,
      entity.attributes.clientConversion,
      analytic,
      entity.attributes.kpi,
      advertiser,
      inversionNet,
      netPrice,
      price,
      entity.attributes.tag,
      entity.attributes.todayMetrics,
      entity.attributes.adopsName,
      entity.attributes.accountName,
      entity.attributes.reportId,
      entity.attributes.margen,
      entity.attributes.office,
      entity.attributes.ownerName,
      entity.attributes.billingEntityName,
      entity.attributes.recordTypeName,
      entity.attributes.accountManager
    );
  }
}
