import { Address } from '../shared/Address';
import { ThirdPartyClient } from './ThirdPartyClient';
import { AdvertiserShared } from '../shared/AdvertiserShared';

export class Advertiser extends AdvertiserShared {
  constructor(
    id = '',
    name = '',
    email = '',
    category = null,
    address = new Address(),
    client = new ThirdPartyClient(),
    activeProductsCount = null,
    users = [],
    opportunityId = ''
  ) {
    super(id, name, email, category, address);
    this.users = users;
    this.activeProductsCount = activeProductsCount;
    this.client = client;
    this.opportunityId = opportunityId;
  }

  payload() {
    const { category, client, users, ...params } = this;

    return {
      userIds: users.length ? users.map(user => user.id) : [],
      categoryId: category?.id,
      clientId: client?.id,
      ...params,
    };
  }

  /**
   *
   * @param {{attributes:Advertiser}} entity
   * @param {object} included
   * @return {Advertiser}
   */
  static create(entity, included) {
    const client = included?.client?.find(client => client.id === entity.relationships?.client.data.id);
    return new Advertiser(
      entity.id,
      entity.attributes.name,
      entity.attributes.email,
      entity.attributes.category,
      entity.attributes.address,
      client,
      entity.attributes.activeProductsCount,
      entity.attributes.users,
      entity.attributes.opportunityId
    );
  }
}
