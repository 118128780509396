import * as RESOURCE from '@/services/keys';
import { EntityParser } from '@/entities/shared/EntityParser';
import { Advertiser } from '@/entities/ThirdParty/Advertiser';
import { Platform } from '@/entities/ThirdParty/Platform';
import { PlatformProvider } from '@/entities/ThirdParty/PlatformProvider';
import { SalesforceFilter } from '@/model/modules/thirdParty/filters/SalesforceFilter';
import { ThirdPartyClient as Client } from './ThirdPartyClient';
import AnalyticsCampaign from '@/entities/ThirdParty/AnalyticsCampaign';
import AnalyticsCampaignBreakdown from '@/entities/ThirdParty/AnalyticsCampaignBreakdown';
import AnalyticsProduct from '@/entities/ThirdParty/AnalyticsProduct';
import AnalyticsProductBreakdown from '@/entities/ThirdParty/AnalyticsProductBreakdown';
import AnalyticsPlatform from '@/entities/ThirdParty/AnalyticsPlatform';
import CommentShared from '@/model/shared/CommentShared';
import Cost from '@/entities/ThirdParty/Cost';
import Currency from '@/entities/shared/Currency';
import IngestionJobNotifications from '@/entities/shared/IngestionJobNotifications.js';
import Product from '@/entities/ThirdParty/Product';
import ProviderConfig from '@/entities/ThirdParty/ProviderConfig';
import UserCore from '@/entities/Auth/UserCore';
import { Opportunity } from '@/entities/shared/Opportunity';
import { ScheduleReport } from '@/entities/ThirdParty/ScheduleReport';

const thirdPartyEntityParser = new EntityParser({
  [RESOURCE.ADVERTISER_RESOURCE]: Advertiser.create,
  [RESOURCE.SALESFORCE_ADVERTISERS_RESOURCE]: Advertiser.create,
  [RESOURCE.AUTHOR_RESOURCE]: UserCore.create,
  [RESOURCE.CLIENT_RESOURCE]: Client.create,
  [RESOURCE.COMMENTS_RESOURCE]: CommentShared.create,
  [RESOURCE.CURRENCY_RESOURCE]: Currency.create,
  [RESOURCE.FILTER_RESOURCE]: SalesforceFilter.create,
  [RESOURCE.PLATFORM_RESOURCE]: Platform.create,
  [RESOURCE.PRODUCT_ANALYTIC_RESOURCE]: AnalyticsProduct.create,
  [RESOURCE.CAMPAIGNS_ANALYTICS_RESOURCE]: AnalyticsCampaign.create,
  [RESOURCE.PLATFORM_ANALYTICS_RESOURCE]: AnalyticsPlatform.create,
  [RESOURCE.PRODUCT_RESOURCE]: Product.create,
  [RESOURCE.OPPORTUNITIES_RESOURCE]: Opportunity.create,
  [RESOURCE.SALESFORCE_OPPORTUNITY_RESOURCE]: Opportunity.create,
  [RESOURCE.SALESFORCE_OPPORTUNITIES_RESOURCE]: Opportunity.create,
  [RESOURCE.PROVIDERS_CONFIG_RESOURCE]: ProviderConfig.create,
  [RESOURCE.PROVIDERS_RESOURCE]: PlatformProvider.create,
  [RESOURCE.USER_RESOURCE]: UserCore.create,
  [RESOURCE.PRODUCT_ANALYTICS_BREAKDOWN_RESOURCE]: AnalyticsProductBreakdown.create,
  [RESOURCE.CAMPAIGNS_ANALYTICS_BREAKDOWN_RESOURCE]: AnalyticsCampaignBreakdown.create,
  [RESOURCE.COST_RESOURCE]: Cost.create,
  [RESOURCE.INGESTION_JOB_NOTIFICATIONS_RESOURCE]: IngestionJobNotifications.create,
  [RESOURCE.SCHEDULE_REPORTS_RESOURCE]: ScheduleReport.create,
  filter: SalesforceFilter.create, // TODO waiting for back to delete this
  client: Client.create, // TODO waiting for back to delete this
  provider_config: ProviderConfig.create, // TODO waiting for back to delete this
  product: Product.create, // TODO: Waiting to past it to plural
});

export default thirdPartyEntityParser;
