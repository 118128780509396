import MetricsCalculated from '@/model/shared/analytics/MetricsCalculated';
import MetricsGlobal from '@/model/shared/analytics/MetricsGlobal';

export default class Metrics {
  constructor(calculated = new MetricsCalculated(), global = new MetricsGlobal(), custom = null) {
    this.calculated = calculated;
    this.global = global;
    this.custom = custom;
  }

  static build(entity) {
    if (entity) {
      const calculated = MetricsCalculated.create(entity.calculated);
      const global = MetricsGlobal.create(entity.global);
      return new Metrics(calculated, global, entity?.custom);
    }
    return new Metrics();
  }
}
